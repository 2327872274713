import Model from './Model'

class Task extends Model {
  static entity = 'task'

  static fields () {
    return {
      id: this.attr(null),
      account_id: this.attr(''),
      type: this.attr(''),
      status: this.attr(''),
      data: this.attr(''),
      result: this.attr(''),
      error: this.attr(''),
      created_at: this.attr(''),
      started_at: this.attr(''),
      updated_at: this.attr(''),
      done_at: this.attr('')
    }
  }

  static getAllRunning () {
    return this.query()
      .where(t => ['OPEN', 'QUEUED', 'RUNNING'].includes(t.status))
      .orderBy('id', 'desc')
      .get()
  }

  get dataParsed () {
    let data = {}
    try {
      data = JSON.parse(this.data)
    } catch (e) {
      console.warn('Task data parse error', e, this.data)
    }
    return data
  }
}

// Periodically ping the server for any currently running tasks
let fetchingTasks = false
setInterval(() => {
  if (fetchingTasks) {
    return
  }
  const runningTasks = Task.getAllRunning()
  if (runningTasks.length) {
    fetchingTasks = true
    console.log('running tasks', runningTasks)
    try {
      Task.fetchMany({
        params: {
          ids: runningTasks.map(task => task.id).join(',')
        },
        hideErrors: true // Don't show errors from this in the top bar
      })
    } finally {
      fetchingTasks = false
    }
  }
}, 10000)

export default Task
