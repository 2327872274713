import Model from './Model'

export default class Config extends Model {
  static entity = 'config'

  static fields () {
    return {
      id: this.attr(undefined),
      account_id: this.attr(undefined),
      namespace: this.attr(undefined),
      key: this.attr(undefined),
      value: this.attr(undefined),
      created_at: this.attr(undefined)
    }
  }

  static findByKey (key, namespace = undefined) {
    return this.query()
      .where(record =>
        (record.key === key) &&
        (
          namespace === undefined ||
          record.namespace === namespace
        )
      )
      .first()
  }

  static findManyByNamespace (namespace) {
    return this.query()
      .where(record => {
        return record.namespace === namespace
      })
      .get()
  }
}