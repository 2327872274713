<template>
  <v-menu
    bottom
    offset-y
  >
    <template
      v-slot:activator="{ on }"
    >
      <v-badge
        bordered
        color="error"
        :content="records.length"
        overlap
        :value="records.length > 0"
      >
        <v-btn
          :class="$vuetify.breakpoint.mdAndDown ? 'plain' : 'ml-4'"
          :color="$vuetify.breakpoint.mdAndDown ? 'blue' : '#e9eef5'"
          :style="$vuetify.breakpoint.mdAndDown ? '' : 'background-color: #e9eef5;'"
          :plain="$vuetify.breakpoint.mdAndDown"
          :outlined="$vuetify.breakpoint.lgAndUp"
          :small="$vuetify.breakpoint.mdAndDown"
          fab
          v-on="on"
        >
          <v-icon
            :class="$vuetify.breakpoint.mdAndDown ? 'white--text' : 'black--text'"
          >
            fa-bell fa-sm 
          </v-icon>
        </v-btn>
      </v-badge>
    </template>
    <v-card
      style="width: 360px; max-width: 100%;"
    >
      <div class="d-flex pa-5 justify-space-between cdseo-grey-bg">
        <div class="font-weight-bold">Notifications</div>
        <v-btn
          v-if="records.length"
          class="body-2 text-decoration-none"
          text
          color="primary"
          @click.stop="clearAll"
          :disabled="loading"
          x-small
        >Clear All</v-btn>
      </div>
      <div
        v-if="!records.length"
        class="pa-5 text-center"
      >
        You have no alerts.
      </div>
      <div
        style="max-height: 400px; overflow-y: auto;"
      >
        <alert-menu-item
          v-for="record in records"
          :key="record.id"
          :record="record"
        />
      </div>
    </v-card>
  </v-menu>
</template>

<script>
import { Alert } from '@/models'
import AlertMenuItem from './AlertMenuItem.vue'

export default {
  components: {
    AlertMenuItem
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    records () {
      return Alert.query()
        .where(r => !!r.uncleared)
        .orderBy('id', 'desc')
        .all()
    }
  },
  methods: {
    async clearAll () {
      this.loading = true
      try {
        await this.$http({
          method: 'POST',
          url: '/api/alert/clear_all'
        })
        Alert.deleteAll()
      } finally {
        this.loading = false
      }
    }
  },
  created () {
    Alert.initPolling()
  }
}
</script>
