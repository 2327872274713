<template>
  <div class="auth-wrapper">
    <div class="auth-container d-flex primary">
      <div class="logo-container">
        <div class="logo">
          <img src="@/assets/logos/cdseo-logo-white.png" />
        </div>
      </div>

      <div class="content-container">
        <div class="text-center logo-container-sm mb-8">
          <div class="logo">
            <img src="@/assets/logos/cdseo-logo-blue.png" />
          </div>
        </div>
        <div>
          <div class="text-center auth-subtitle" color="primary">Dashboard</div>
          <h1 class="text-center auth-title mb-8" v-if="!register">
            CDSEO: All-In-One SEO App
          </h1>
          <h1 class="text-center auth-title mb-8" v-else>Register Your Organization</h1>

          <v-alert
            v-if="$route.query._cr"
            color="success"
            dark
            class="mb-8"
            icon="fa-brands fa-shopify"
          >
            Complete the setup process by choosing how you want to sign in to your workspace.
          </v-alert>

          <div
            v-if="!withEmail"
            class="text-center mb-4"
          >
            <v-btn
              class="text-capitalize pa-8 font-weight-bold text-body-1"
              color="white"
              elevation="1"
              :disabled="loading"
              :href="googleAuthUrl"
            >
              <img src="@/assets/icon-google.svg" height="20" class="mr-4" />
              Sign in with Google</v-btn>
          </div>

          <div 
            v-if="!withEmail"
            class="text-center"
          >
            <div class="mb-4">or</div>
            <a
            @click="withEmail = true"
            >
              Sign-In with an Email Address
            </a>
          </div>

          <v-form
            v-else-if="withEmail"
            class="auth-form pt-4"
            @submit.prevent="submit"
          >
            <div
              v-if="requested"
              class="text-center mb-5"
            >
              <h3
                class="mb-3"
              >Passcode Verification</h3>
            </div>
            <v-text-field
              v-if="!requested"
              label="Email Address"
              outlined
              append-icon="fa-at"
              v-model="email"
              hide-details
              class="mb-5"
              @input="requested = false"
              :disabled="loading"
              autocomplete="email"
            ></v-text-field>

            <v-text-field
              v-if="requested"
              label="Passcode"
              outlined
              append-icon="fa-key"
              v-model="password"
              hide-details
              class="mb-5"
              :disabled="loading"
            ></v-text-field>

            <v-alert
              v-if="error && !loading"
              text
              color="error"
              icon="fa-solid fa-exclamation-circle"
              class="font-weight-bold mb-6"
            >{{ error }}</v-alert>

            <v-alert
              v-else-if="requested && !loading"
              text
              color="success"
              icon="fa-solid fa-check"
              class="font-weight-bold mb-6"
            >A message with a one-time password code has been emailed to the address provided. Enter the code to continue.</v-alert>

            <v-btn
              v-if="requested"
              block
              x-large
              color="primary"
              class="text-capitalize"
              type="submit"
              :loading="loading"
            >Sign In</v-btn>

            <v-btn
              v-else
              block
              x-large
              color="primary"
              class="text-capitalize"
              type="submit"
              :loading="loading"
            >Sign In</v-btn>
          </v-form>

          <div
            v-if="withEmail"
            class="text-center mt-10"
          >
            <a
              text
              color="primary"
              @click="withEmail = requested = false"
            >&lt; Back</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.auth-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10000000;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;

  .auth-container {
    width: 100%;
    height: 100%;
    .logo-container {
      width: 580px;
      height: 100%;
      background: url('@/assets/logos/icon-lg-login.png') no-repeat right bottom;
      .logo {
        position: absolute;
        padding: 2em;
      }
    }
    .content-container {
      width: calc(100% - 580px);
      height: 100%;
      background-color: #fff;
      overflow-y: auto;
      display: flex;
      align-items: center;
      padding: 3em;
      justify-content: center;
      .logo-container-sm {
        display: none;
      }
      .auth-title {
        line-height: 1.1em;
      }
      .auth-form .v-input:not(.v-input--is-focused) fieldset {
        border-color: #6e8ca2;
      }
      .auth-form .v-input:not(.v-input--is-focused) .v-icon {
        color: #6e8ca2;
      }
      .auth-form .v-input:not(.v-input--is-focused) label {
        color: #6e8ca2;
      }
      .auth-subtitle {
        font-weight: bold;
        color: #0269a5;
      }
      .auth-footer {
        font-size: 0.8em;
      }
    }
  }
}

@media screen and (max-width: 960px) {
  .auth-wrapper {
    .auth-container {
      width: 100%;
      height: 100%;
      .logo-container {
        display: none;
      }
      .content-container {
        width: 100%;
        display: block;

        .logo-container-sm {
          display: block;
        }
      }
    }
  }
}
</style>

<script>
export default {
  data () {
    return {
      loading: false,
      register: false,
      email: null,
      password: null,
      requested: false,
      error: null,
      withEmail: false,
      validated: true, // TEMP for testing
    };
  },
  computed: {
    googleAuthUrl () {
      const currentHost = window.location.host
      const subdomain = currentHost.split('.')[0]
      const newHost = `app.${currentHost.split('.').slice(1).join('.')}`
      const state = {
        h: subdomain
      }
      if (Object.entries(this.$route.query).length) {
        state.q = this.$route.query
      } else {
        state.url = window.location.href
      }
      return `${window.location.protocol}//${newHost}/api/auth/google?state=${encodeURIComponent(JSON.stringify(state))}`
    }
  },
  methods: {
    async submit () {
      if (this.requested) {
        return this.login()
      }
      return this.requestPassword()
    },
    async requestPassword () {
      this.error = null
      this.loading = true
      try {
        await this.$http({
          method: 'POST',
          url: '/api/auth/init',
          data: {
            email: this.email,
            ...(this.$route.query.__token
              ? {
                  __token: this.$route.query.__token
                }
              : {}
            ),
            ...(this.$route.query.invitation_token
              ? {
                  invitation_token: this.$route.query.invitation_token
                }
              : {}
            )
          }
        })
        this.requested = true
      } catch (e) {
        this.error = e.response?.data?.error || e.response?.data
      } finally {
        this.loading = false
      }
    },
    async login () {
      this.error = null
      this.loading = true
      try {
        const response = await this.$http({
          method: 'POST',
          url: '/api/auth/local',
          auth: {
            username: this.email,
            password: this.password
          },
          data: {
            ...(this.$route.query.__token
              ? {
                  __token: this.$route.query.__token,
                  _cr: true
                }
              : {}
            ),
            ...(this.$route.query.invitation_token
              ? {
                  invitation_token: this.$route.query.invitation_token
                }
              : {}
            )
          }
        })

        if (this.$route?.query?._r) {
          // TODO: Validate this url to ensure that someone hasn't tampered with the redirect url.
          // In my opinion this is low priority for now and maybe ever, but recommend revisiting later if there's ever a
          // motivation where someone would even want to exploit this (if they even can).
          window.location = this.$route.query._r
        } else {
          this.$store.dispatch('handleAuth', response.data?.data)
        }
      } catch (e) {
        console.log('e', e)
        this.error = (e.response?.status + '') === '401' ? 'The passcode you entered is invalid.' : (e.response?.data?.error || e.response?.data)
        this.loading = false
      }
    },
    reset (value) {
      this.register = value
      this.requested = false
      this.error = false
    }
  }
};
</script>
