import Vue from 'vue'
import Vuex from 'vuex'
import VuexORM from '@vuex-orm/core'
import VuexORMAxios from '@vuex-orm/plugin-axios'
import * as Models from '../models'
import getRolesForUserAndAccount from 'shared/permissions/getRolesForUserAndAccount'
import getPrivilegesForRoles from 'shared/permissions/getPrivilegesForRoles'
import { uniq } from 'lodash'
import planTypes from 'shared/const/planTypes'
import http, { configureHttp } from '@/plugins/http'

Vue.use(Vuex)


VuexORM.use(VuexORMAxios, {
  baseURL: '/api/',
  axios: http
})

const database = new VuexORM.Database()
Models.registerModels(database)

let errorTimeout
let queuedImageOptimizationsTimeout = null

const store = new Vuex.Store({
  plugins: [VuexORM.install(database)],
  state: {
    loading: false,
    navVisible: false,
    navCollapse: false,
    userId: null,
    accountId: null,
    accountIds: [],
    requireAuth: null,
    appError: null,
    queuedImageOptimizations: 0,
    superuserOverride: false
  },
  getters: {
    showAuth (state, getters) {
      return !getters.user || state.requireAuth
    },
    user (state) {
      return Models.User.find(state.userId)
    },
    account (state) {
      return Models.Account.find(state.accountId)
    },
    accountUser (state, getters) {
      return getters.user &&
        getters.account &&
        Models.AccountUser.query()
          .where(r => r.user_id + '' === getters.user?.id + '' && r.account_id === getters.account?.id)
          .first()
    },
    accounts (state) {
      return uniq(state.accountIds || []).map(id => Models.Account.find(id))
    },
    billing (state, getters) {
      try {
        return JSON.parse(getters?.account?.billing || '{}')
      } catch (e) {
        return {}
      }
    },
    credits (state, getters) {
      return {
        ...getters.billing?.credits || {},
        // Since optimization tasks are asynchronous, I want to include the # of queued jobs in this so the number is optimistically low
        imageOptimization: Math.max((getters?.billing?.credits?.imageOptimization || 0) - (state.queuedImageOptimizations || 0), 0)
      }
    },
    planType (state, getters) {
      return planTypes.find(planType => planType.id === getters.billing?.plan)
    },
    modules (state, getters) {
      return [
        ...(getters.account?.modules || '')
          .split(',')
          .filter(v => !!v),
        ...(getters.planType?.modules || [])
      ]
    },
    roles (state, getters) {
      return uniq([
        ...(getters.user?.roles || '').split(',').filter(v => !!v),
        ...(getters.accountUser?.roles || '').split(','),
        ...getRolesForUserAndAccount(
          [
            ...(getters.user?.roles || '').split(','),
            ...(getters.accountUser?.roles || '').split(',')
          ].filter(v => !!v)
          ,
          getters.modules
        )
      ])
    },
    privileges (state, getters) {
      return getPrivilegesForRoles(getters.roles)
    },
    systemLoading () {
      const task = Models.Task.getAllRunning().find(t => t.type === 'pullAllPages')
      if (!task) {
        return false
      }
      let taskData = {}
      try {
        taskData = JSON.parse(task.data || '{}')
      } catch (e) {
        //
      }
      const types = ['PRODUCT', 'COLLECTION', 'PAGE', 'ARTICLE']

      const totalCount = types.reduce((accumulator, type) => accumulator + +(taskData[type] || 0), 0)
      const successCount = types.reduce((accumulator, type) => accumulator + +(taskData[type + '_success'] || 0), 0)
      const errorCount = types.reduce((accumulator, type) => accumulator + +(taskData[type + '_error'] || 0), 0)

      const result = {
        ...taskData,
        totalCount,
        successCount,
        errorCount,
        progress: 100 * (successCount + errorCount) / (totalCount || 1)
      }

      types.forEach(type => {
        result[type + '_done'] = +(taskData[type + '_success'] || 0) + +(taskData[type + '_error'] || 0)
        result[type] = taskData[type] || 0
        result[type + '_progress'] = 100 * result[type + '_done'] / (result[type] || 1)
      })

      return result
    },
    shopifyIntegration () {
      let integration = Models.Integration.query()
        .where(record => record.type === 'SHOPIFY')
        .first()

      if (integration?.public) {
        try {
          integration = {
            ...integration,
            public: JSON.parse(integration.public)
          }
        } catch (e) {
          //
        }
      }
      if (integration?.private) {
        try {
          integration = {
            ...integration,
            private: JSON.parse(integration.private)
          }
        } catch (e) {
          //
        }
      }

      return integration
    },
    accountUsers (state, getters) {
      return Models.AccountUser.query()
        .where(r => r.id > 0 && r.account_id === getters.account?.id)
        .get()
        .map(record => {
          return {
            ...record,
            user: Models.User.find(record.user_id)
          }
        })
    },
    planUsersExceeding (state, getters) {
      if (
        !state.superuserOverride &&
        !state.loading &&
        getters.planType &&
        getters.planType.users < (getters.accountUsers?.length || 1)
      ) {
        return getters.accountUsers.length - getters.planType.users
      }
      return null
    }
  },
  mutations: {
    setLoading (state, value) {
      state.loading = value
    },
    setNavVisible (state, value) {
      state.navVisible = value
    },
    setNavCollapse (state, value) {
      state.navCollapse = value
    },
    setRequireAuth (state, value) {
      state.requireAuth = value
    },
    setAppError (state, value) {
      clearTimeout(errorTimeout)
      state.appError = value
      if (value) {
        errorTimeout = setTimeout(() => {
          state.appError = null
        }, 5000)
      }
    },
    setSession (state, value) {
      if (value.user) {
        Models.User.insert({
          data: value.user
        })
        state.requireAuth = false
      }
      if (value.users?.length) {
        Models.User.insert({
          data: value.users
        })
      }
      if (value.accountUser?.id) {
        Models.AccountUser.insert({
          data: value.accountUser
        })
      }
      if (value.accountUsers?.length) {
        Models.AccountUser.insert({
          data: value.accountUsers
        })
      }
      state.userId = value?.user?.id || null
      if (value.account) {
        Models.Account.insert({
          data: value.account
        })
      }
      state.accountId = value?.account?.id || null
      if (value.accounts?.length) {
        Models.Account.insert({
          data: value.accounts
        })
      }
      state.accountIds = (value?.accounts || []).map(account => account.id)

      if (value.integrations?.length) {
        Models.Integration.insert({
          data: value.integrations
        })
      }
      if (value.configs?.length) {
        Models.Config.insert({
          data: value.configs
        })
      }
      if (value.tasks?.length) {
        Models.Task.insert({
          data: value.tasks
        })
      }
    },
    setQueuedImageOptimizations (state, value) {
      state.queuedImageOptimizations = value
    },
    setSuperuserOverride (state, value) {
      state.superuserOverride = value
    }
  },
  actions: {
    async checkQueuedImageOptimizations ({ commit, state, dispatch }) {
      clearTimeout(queuedImageOptimizationsTimeout)
      try {
        const response = await Vue.$http({
          method: 'GET',
          url: '/api/image_optimization/queued'
        })

        commit('setQueuedImageOptimizations', response?.data?.data?.count)
      } finally {
        if (state.queuedImageOptimizations > 0) {
          clearTimeout(queuedImageOptimizationsTimeout)
          queuedImageOptimizationsTimeout = setTimeout(() => {
            dispatch('checkQueuedImageOptimizations')
          }, 30 * 1000)
        }
      }
    },
    async getSession ({ commit, getters, dispatch }) {
      commit('setLoading', true)
      try {
        const queryString = window.location.search
        const urlParams = new URLSearchParams(queryString)
        const [response] = await Promise.all([
          Vue.$http({
            method: 'GET',
            url: '/api/auth/me',
            params: {
              ...(urlParams.get('invitation_token')
              ? {
                  invitation_token: urlParams.get('invitation_token')
                }
              : {})
            }
          })
        ])

        dispatch('handleAuth', response.data?.data)
      } catch (e) {
        //
      } finally {
        commit('setLoading', false)
      }
    },
    async handleAuth ({ getters, commit, dispatch }, data) {
      commit('setSession', data)

      if (getters?.account?.id) {
        dispatch('checkQueuedImageOptimizations')
        // If they don't have an integration then redirect to the install page
        if (!getters?.shopifyIntegration?.active) {
          window.location = 'https://cdseo.com/install'
          return
        } else if (!getters?.billing?.plan) {
          // Require a plan to be selected
          window.location = '/select-plan'
          return
        }
      }
      // Verify that the current route is allowed.
      const router = require('../router')
      if (router.default?.currentRoute) {
        let goNext = null
        router.beforeEach(router.default?.currentRoute, null, (_goNext) => goNext = _goNext)
        if (goNext) {
          router.default.replace(goNext)
        }
      }
    },
    async endSession ({ commit }) {
      commit('setLoading', true)
      try {
        const response = await Vue.$http({
          method: 'DELETE',
          url: '/api/auth/me'
        })
        commit('setSession', {})
        Models.Alert.stopPolling()
      } catch (e) {
        //
      } finally {
        commit('setLoading', false)
      }
    }
  }
})

configureHttp(store, Models)
Vue.$http = Vue.prototype.$http = window.$http = http

window.$store = store

export default store
