const allPrivileges = require('./privileges')
const allRoles = require('./roles')
const intersection = require('lodash/intersection')
const uniq = require('lodash/uniq')

/**
 * Get a list of all privileges that a list of user roles has, this includes
 * roles that are inherited from other roles, IE ADMIN gets the role TECHNICAL, CONTENT, and CREATOR automatically.
 * @param {Array} roles - A list of roles that the current user has
 */
 const getPrivilegesForRoles = (roles) => {
  const privileges = uniq(
    roles.reduce((accumulator, role) => {
    return [
        ...accumulator,
        ...(allRoles[role]?.privileges || [])
      ]
    }, [])
  )
  return intersection(privileges, Object.keys(allPrivileges))
}

module.exports = getPrivilegesForRoles
