import Model from './Model'

export default class ImageOptimization extends Model {
  static entity = 'image_optimization'

  static fields () {
    return {
      id: this.attr(null),
      account_id: this.attr(undefined),
      page_id: this.attr(undefined),
      external_id: this.attr(undefined),
      status: this.attr(undefined),
      task_id: this.attr(undefined),
      ignore: this.attr(undefined),
      already_optimized: this.attr(undefined),
      url: this.attr(undefined),
      url_signed: this.attr(undefined), // AWS S3 signed url so we don't need to make objects public
      alt: this.attr(undefined),
      size: this.attr(undefined),
      width: this.attr(undefined),
      height: this.attr(undefined),
      quality_optimized: this.attr(undefined),
      url_optimized: this.attr(undefined),
      alt_optimized: this.attr(undefined),
      size_optimized: this.attr(undefined),
      width_optimized: this.attr(undefined),
      height_optimized: this.attr(undefined),
      alt_manual: this.attr(undefined),
      meta: this.attr(undefined),
      created_at: this.attr(undefined)
    }
  }
}