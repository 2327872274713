import axios from 'axios'

const axiosInstance = axios.create()

const configureHttp = (store, Models) => {
  axiosInstance.interceptors.request.use((config) => {
    const skipHeaders = (config?.skipHeaders || []).map(h => ((h || '') + '').toLowerCase())
  
    config.headers = {
      ...(config.headers || {}),
      'x-client-version': process.env?.VUE_APP_CLIENT_VERSION || '0.1.0'
    }
    if (store?.getters?.accountId && !skipHeaders.includes('x-account-id') &&
      [undefined, null].includes(config.headers['x-account-id'])
    ) {
      config.headers['x-account-id'] = store.getters.accountId + ''
    }
    return config
  })

  if (store) {
    axiosInstance.interceptors.response.use((response) => {
      if (response?.data?.relationships && Models) {
        Models.addRecordsToStore(response.data.relationships, { force: true })
      }

      return response
    }, (error) => {
      // Ignore errors resulting from cancelled requests
      if (error.message.includes('request cancelled') || (error.constructor && error.constructor.name === 'Cancel')) {
        throw error // The component triggering this should handle the error
      }
      console.error({ error })
      let message = error.message
      if (error.response) {
        message = error?.response?.data?.error || error?.response?.data || message
      }
      if (!(error?.hideErrors || error?.config?.hideErrors)) {
        if ((error?.response?.status || '') + '' === '401') {
          store.commit('setRequireAuth', true)
        } else {
          store.commit('setAppError', message)
        }
      }
      throw error
    })
  }
}

export default axiosInstance
export { configureHttp }
