import Model from './Model'

export default class Account extends Model {
  static entity = 'account'

  static fields () {
    return {
      id: this.attr(null),
      name: this.attr(undefined),
      subdomain: this.attr(undefined),
      website_url: this.attr(undefined),
      modules: this.attr(undefined),
      settings: this.attr(undefined),
      billing: this.attr(undefined),
      timezone: this.attr(undefined),
      created_at: this.attr(undefined),
      last_seen_at: this.attr(undefined)
    }
  }

  get billingData () {
    let billing = {}
    try {
      billing = JSON.parse(this.billing)
    } catch (e) {
      console.warn(e)
    }
    return billing
  }

  getCredits (key) {
    if (this.billingData?.plan === 'ENTERPRISE') {
      return 10000
    }
    return this.billingData?.credits?.[key] || 0
  }
}