var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[(
      _vm.$store.getters.account &&
      _vm.$store.getters.billing.plan &&
      (
        _vm.$store.getters.billing.plan === 'ENTERPRISE' ||
        !!_vm.$store.getters.billing.period_end_at
      ) &&
      (
        !_vm.$store.getters.showAuth ||
        _vm.$store.getters.user
      ) &&
      !_vm.$store.getters.planUsersExceeding
    )?[_c('left-navigation'),(_vm.$vuetify.breakpoint.mdAndDown)?_c('div',[_c('div',{staticClass:"mobile-menu"},[_c('div',{staticClass:"d-flex justify-space-between align-center",attrs:{"color":"primary"}},[_c('div',{staticClass:"py-5 px-8",staticStyle:{"cursor":"pointer"},attrs:{"width":"120"},on:{"click":function($event){return _vm.$store.commit('setNavVisible', true)}}},[(!_vm.isHomePage)?_c('v-icon',{attrs:{"fab":"","color":"white"}},[_vm._v(" fa-solid fa-bars ")]):_vm._e()],1),_c('div',{staticClass:"pa-5 center"},[_c('router-link',{staticClass:"d-inline-flex",attrs:{"to":"/"}},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/logos/cdseo-logo-white.png"),"width":"180","height":"45","alt":"Dashboard"}})])],1),_c('div',{staticClass:"py-5 px-8"},[(_vm.$vuetify.breakpoint.mdAndDown)?_c('alert-menu'):_vm._e()],1)])])]):_vm._e(),_c('v-main',{class:{ 'full-width': _vm.$route.meta?.noPadding },style:(_vm.$vuetify.breakpoint.mdAndDown ? { height: 'calc(100% - 92px)' } : {})},[_c('div',{class:_vm.$route.meta?.noPadding ? [] : [
          _vm.$vuetify.breakpoint.xs ? 'pt-6 pa-2' : '',
          _vm.$vuetify.breakpoint.sm ? 'pa-4' : '',
          _vm.$vuetify.breakpoint.md ? 'pa-6' : '',
          _vm.$vuetify.breakpoint.lgAndUp ? 'pa-10' : ''
        ],staticStyle:{"height":"100%"}},[_c('router-view')],1)])]:(_vm.$store.getters.account && _vm.$store.getters.planUsersExceeding)?[_c('div',{staticClass:"pa-10 text-center"},[_c('img',{attrs:{"src":require(_vm.$vuetify.theme.dark ? '@/assets/logos/cdseo-logo-white.png' : '@/assets/logos/cdseo-logo-blue.png')}})]),_c('force-remove-account-users-dialog')]:_vm._e(),(_vm.$store.state.appError)?_c('v-alert',{staticStyle:{"position":"fixed","top":"0","left":"0","width":"100%","z-index":"100000000"},attrs:{"icon":"fa fa-exclamation-circle","dark":"","color":"error","dismissible":""},on:{"input":function($event){return _vm.$store.commit('setAppError', null)}}},[_c('span',[_vm._v(_vm._s(_vm.$store.state.appError))])]):_vm._e(),(_vm.$store.getters.showAuth)?_c('auth-view'):(!_vm.$store.getters.account)?_c('accounts-view'):_vm._e(),(_vm.$store.state.loading)?_c('div',{staticStyle:{"width":"100%","height":"100%","position":"fixed","left":"0","top":"0","display":"flex","align-items":"center","justify-content":"center","z-index":"100000000","background-color":"rgba(0, 0, 0, 0.4)"}},[_c('div',{staticClass:"rounded",staticStyle:{"width":"280px","padding":"60px","text-align":"center"},style:(_vm.$vuetify.theme.dark ? 'background-color: #292929' : 'background-color: #fff;')},[_c('img',{staticStyle:{"width":"100%","max-width":"150px"},attrs:{"src":"https://s3.amazonaws.com/cdn.cdseo.com/assets/cdseo-loading.gif?v=7"}})])]):_vm._e(),(_vm.showShopifyAppEmbedInstructions)?_c('shopify-app-embed-instructions'):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }