<template>
<v-container class="full-width fill-height pa-0 ma-0" style="max-width: none;">


  <v-card flat class="fill-height pa-8 full-width">

    <div class="d-md-flex justify-space-between align-start align-items-start full-width">

      <div>
        <v-card-title class="pa-1 ma-0 text-no-wrap">
          Admins <template v-if="!loading">({{ adminUsers.length > 0 ? adminUsers.length : 0 }})</template>
        </v-card-title>

        <v-card-text class="d-flex flex-wrap pb-2 pl-0"
          v-if="loading"
        >
          <v-skeleton-loader
            type="avatar"
            loading
            class="mr-1"
          />
        </v-card-text>

        <v-card-text class="d-flex flex-wrap pb-2 pl-0"
          v-if="adminUsers.length > 0 && !loading"
        >
          <div
            v-for="record in adminUsers"
            :key="record.id"
          >
            <user-avatar
              :record-id="record.user_id"
            />
          </div>
        </v-card-text>
      </div>

      <div>
        <v-card-title class="pa-1 ma-0 text-no-wrap" :class="{ 'mt-4': $vuetify.breakpoint.xs}">
          Content Creators <template v-if="!loading">({{ contentUsers.length > 0 ? contentUsers.length : 0 }})</template >
        </v-card-title>

        <v-card-text class="d-flex flex-wrap pb-2 pl-0"
          v-if="loading"
        >
          <v-skeleton-loader
            type="avatar"
            loading
            class="mr-1"
          />
        </v-card-text>

        <v-card-text class="d-flex flex-wrap pb-2 pl-0"
          v-if="contentUsers.length > 0 && !loading"
        >
          <div
            v-for="record in contentUsers"
            :key="record.id"
            >
              <user-avatar
                :record-id="record.user_id"
              />
          </div>
        </v-card-text>
        <v-card-text class="pl-1 body-2" v-else-if="!loading">
          Invite others to help manage your SEO Content.
        </v-card-text>
      </div>

      <div>
        <v-card-title class="pa-1 ma-0 text-no-wrap" :class="{ 'mt-4': $vuetify.breakpoint.xs}">
          Tech Users <template v-if="!loading">({{ technicalUsers.length > 0 ? technicalUsers.length : 0 }})</template>
        </v-card-title>

        <v-card-text class="d-flex flex-wrap pb-2 pl-0"
          v-if="loading"
        >
          <v-skeleton-loader
            type="avatar"
            loading
            class="mr-1"
          />
        </v-card-text>

        <v-card-text class="d-flex flex-wrap pb-2 pl-0"
          v-if="technicalUsers.length > 0 && !loading"
        >
          <div
            v-for="record in technicalUsers"
            :key="record.id"
          >
            <user-avatar
              :record-id="record.user_id"
            />
          </div>
        </v-card-text>
        <v-card-text class="pl-1 body-2" v-else-if="!loading">
           Invite others to help manage your your Technical SEO.
        </v-card-text>
      </div>

      <v-divider vertical />

      <v-btn
        to="/settings/members"
        class="mt-4"
        color="primary"
        large
        :class="{ 'full-width m-0': $vuetify.breakpoint.xs}"
      >Manage Users</v-btn>

    </div>

  </v-card>

</v-container>
</template>

<script>
import UserAvatar from '@/components/UserAvatar'
import { AccountUser, User } from '@/models'
import roles from 'shared/permissions/roles.js'

export default {
    components: {
      UserAvatar
    },
    data () {
    return {
      loading: false
    }
    },
    computed: {
      accountUsers () {
        return AccountUser.query()
        .where(u => u.id > 0 && u.account_id + '' === this.$store.getters.account?.id + '')
        .get()
        .map(record => {
          return {
          ...record,
          roles: (record.roles || '').split(',')
            .filter(v => !!v && roles[v]),
          user: User.find(record.user_id)
          }
        })
      },
      adminUsers () {
        return this.accountUsers.filter(record => {
        const roles = record.roles || []
        return roles.includes('ADMIN')
        })
      },
      contentUsers () {
        return this.accountUsers.filter(record => {
        const roles = record.roles || []
        return roles.includes('CONTENT') && !roles.includes('ADMIN')
        })
      },
      technicalUsers () {
        return this.accountUsers.filter(record => {
          const roles = record.roles || []
          return roles.includes('TECHNICAL') && !roles.includes('ADMIN')
        })
      }
    },
    async created () {
      if (this.$hasRole('ADMIN')) {
        this.loading = true
        try {
          await AccountUser.fetchMany()
        } finally {
          this.loading = false
        }
      }
    }
}
</script>
