import Account from './Account'
import AccountUser from './AccountUser'
import Alert from './Alert'
import Config from './Config'
import ImageOptimization from './ImageOptimization'
import Integration from './Integration'
import NotFoundError from './NotFoundError'
import ChangeLog from './ChangeLog'
import Page from './Page'
import PageCount from './PageCount'
import PageUser from './PageUser'
import Recommendation from './Recommendation'
import Task from './Task'
import User from './User'
import { omit } from 'lodash'

const models = {
  Account,
  AccountUser,
  Alert,
  Config,
  ImageOptimization,
  Integration,
  NotFoundError,
  ChangeLog,
  Page,
  PageCount,
  PageUser,
  Recommendation,
  Task,
  User
}

window.$models = models

export default models

const emptyAccountData = () => {
  Object.values(omit(models, ['Account', 'AccountUser', 'User'])).forEach(model => model.deleteAll())
}

const registerModels = (database) => {
  Object.values(models).map(m => database.register(m))
}

/**
 *
 * @param {*} recordArrays Expects an object keyed by entity names
 * {
 *   "users": [
 *     { "id": 123 }
 *   ]
 * }
 *
 * @param {Boolean} options.force - Force all records to be added to the store
 *  instead of looking for components that need them
 */
const addRecordsToStore = (recordArrays, { force } = {}) => {
  if (!recordArrays) {
    return
  }
  const ComponentRegistry = require('./ComponentRegistry').default
  Object.entries(recordArrays).forEach(([entity, records]) => {
    const Model = modelForEntity(entity)
    if (Model) {
      records.forEach(record => {
        const listeners = ComponentRegistry.getListenersForRecord(entity, record)
        if (listeners.length || force) {
          // Merge with existing record in case fields are missing due to permissions
          // For example, users returned from other endpoints have less fields than users returned
          // from session endpoint
          if (Model.entity === 'users') {
            const existingRecord = Model.find(record[Model.primaryKey])
            if (existingRecord) {
              record = {
                ...existingRecord,
                ...record
              }
            }
          }
          Model.insert({
            data: Model.transformIncomingData({ data: [record] })
          })
          listeners.forEach(listener => listener.insertId(record._id))
        }
      })
    }
  })
}

const countAllRecords = () => {
  return Object.entries(models).reduce((sum, [name, model]) => {
    return model.all().length + sum
  }, 0)
}

const modelForEntity = (entity) => {
  return models[entity] || Object.values(models).find(model => model.entity === entity)
}

export {
  registerModels,
  addRecordsToStore,
  emptyAccountData,
  modelForEntity,
  countAllRecords,
  Account,
  AccountUser,
  Alert,
  Config,
  ImageOptimization,
  Integration,
  NotFoundError,
  ChangeLog,
  Page,
  PageCount,
  PageUser,
  Recommendation,
  Task,
  User
}
