import Model from './Model'

export default class ChangeLog extends Model {
  static entity = 'change_log'

  static fields () {
    return {
      id: this.attr(undefined),
      account_id: this.attr(undefined),
      user_id: this.attr(undefined),
      record_type: this.attr(undefined),
      record_id: this.attr(undefined),
      action: this.attr(undefined),
      created_at: this.attr(undefined),
      data: this.attr(undefined),
      Page: this.attr(undefined)
    }
  }
}