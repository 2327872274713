import Model from './Model'

export default class PageCount extends Model {
  static entity = 'page_count'

  static fields () {
    return {
      id: this.attr(null),
      count: this.attr(null),
      at: this.attr(null),
      loading: this.attr(null)
    }
  }

  static async refresh (id) {
    const record = this.find(id)
    if (record) {
      this.update({
        where: id,
        data: {
          loading: true
        }
      })
    }
    try {
      const result = await this.fetchOne(
        id,
        {
          force: true,
          multiplex: true,
          request: {
            hideErrors: true
          }
        }
      )

      return result
    } finally {
      if (record) {
        this.update({
          where: id,
          data: {
            loading: false
          }
        })
      }
    }
  }
}
