const roles = {
  SUPERUSER: {
    name: 'Superuser',
    hidden: true,
    roles: [
      'ADMIN'
    ],
    privileges: [
      'User.Create',
      'User.Read',
      'User.Update',
      'User.Delete',
      'Integration.Create',
      'Integration.Read',
      'Integration.Update',
      'Integration.Delete'
    ]
  },
  ADMIN: {
    name: 'Admin',
    roles: [
      'TECHNICAL',
      'CONTENT'
    ],
    privileges: [
      'AccountUser.Create',
      'AccountUser.Read',
      'AccountUser.Update',
      'AccountUser.Delete',
      'Integration.Read'
    ]
  },
  TECHNICAL: {
    name: 'Technical',
    privileges: [
      // Define any privileges the TECHNICAL role has access to
      'Page.Read',
      'Page.Update',
      'Integration.Read'
    ],
    modules: [] // No modules are required for this role to be available
  },
  CONTENT: {
    name: 'Content',
    privileges: [
      'File.Create',
      'File.Delete',
      'Page.Create',
      'Page.Read',
      'Page.Update',
      'Page.Delete',
      'PageUser.Create',
      'PageUser.Read',
      'PageUser.Update',
      'PageUser.Delete',
      'Integration.Read'
    ],
    modules: [] // No modules are required for this role to be available
  }
}

module.exports = roles
