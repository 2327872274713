const planTypes = [
  {
    id: 'BASIC',
    name: 'Basic',
    description: 'For Individuals',
    incentives: [
      [
        'Tech',
        [
          'Optimize Listings',
          'Repair Links',
          'Thin Content',
          'Optimize Images',
          'Rich Results',
          'Verify Your Site'
        ]
      ],
      [
        'Content',
        [
          'Pages Content',
          'Blog Content'
        ]
      ]
    ],
    modules: ['CONTENT-PAGE', 'CONTENT-ARTICLE', 'A+'],
    users: 1,
    price: 2900,
    monthlyCredits: {
      imageOptimization: 500,
      aiMetaDescription: 500
    }
  },
  {
    id: 'STANDARD',
    name: 'Standard',
    description: 'For Small Businesses',
    incentives: [
      [
        'Tech',
        [
          'Optimize Listings',
          'Repair Links',
          'Thin Content',
          'Optimize Images',
          'Rich Results',
          'Verify Your Site'
        ]
      ],
      [
        'Content',
        [
          'Pages Content',
          'Blog Content'
        ]
      ]
    ],
    modules: ['CONTENT-PAGE', 'CONTENT-ARTICLE', 'A+'],
    users: 3,
    price: 5900,
    monthlyCredits: {
      imageOptimization: 2500,
      aiMetaDescription: 1000
    }
  },
  {
    id: 'PRO',
    name: 'Pro',
    description: 'For Established Businesses',
    incentives: [
      [
        'Tech',
        [
          'Optimize Listings',
          'Repair Links',
          'Thin Content',
          'Optimize Images',
          'Rich Results',
          'Verify Your Site'
        ]
      ],
      [
        'Content',
        [
          'Pages Content',
          'Blog Content',
          'Collections Content',
          'Products Content'
        ]
      ]
    ],
    modules: ['CONTENT-PAGE', 'CONTENT-ARTICLE', 'A+'],
    users: 5,
    price: 9900,
    monthlyCredits: {
      imageOptimization: 5000,
      aiMetaDescription: 5000
    }
  },
  {
    id: 'ENTERPRISE',
    name: 'Enterprise',
    users: 50,
    price: 0,
    description: 'Enterprise',
    modules: ['CONTENT-PAGE', 'CONTENT-ARTICLE', 'CONTENT-PRODUCT', 'CONTENT-COLLECTION', 'A+'],
    hidden: true
  }
]

module.exports = planTypes
