import Model from './Model'

export default class Recommendation extends Model {
  static entity = 'recommendation'

  static fields () {
    return {
      id: this.attr(null),
      account_id: this.attr(undefined),
      type: this.attr(undefined),
      data: this.attr(undefined),
      loading: this.attr(undefined),
      created_at: this.attr(undefined)
    }
  }

  static async refresh (id) {
    const record = this.find(id)
    if (record) {
      this.update({
        where: id,
        data: {
          loading: true
        }
      })
    } else {
      this.insert({
        data: {
          id,
          loading: true
        }
      })
    }
    try {
      const result = await this.fetchOne(
        id,
        {
          force: true,
          multiplex: true,
          request: {
            hideErrors: true
          }
        }
      )

      return result
    } finally {
      this.update({
        where: id,
        data: {
          loading: false
        }
      })
    }
  }
}
