import Model from './Model'

export default class PageUser extends Model {
  static entity = 'page_user'

  static fields () {
    return {
      id: this.attr(null),
      page_id: this.attr(''),
      user_id: this.attr(''),
      created_at: this.attr('')
    }
  }
}
