const allRoles = require('./roles')
const uniq = require('lodash/uniq')
const intersection = require('lodash/intersection')

/**
  * Get a list of all roles that an user has access to depending on the account's modules
  * @param {Array} userRoles - A list of roles that the current user has
  * @param {Array} accountModules - A list of modules the account has.  Some roles require account modules to exist
  */
const getRolesForUserAndAccount = (userRoles, accountModules) => {
  // Filter out roles that require modules that the account doesn't have
  userRoles = (userRoles || []).filter(role =>
    !allRoles[role]?.modules?.length ||
    !!intersection(accountModules || [], allRoles[role]?.modules).length
  )
  if (!userRoles?.length) {
    return []
  }
  const roles = uniq([
    ...userRoles,
    ...userRoles.reduce((accumulator, role) => {
      return [
        ...accumulator,
        ...getRolesForUserAndAccount(allRoles[role]?.roles, accountModules)
      ]
    }, [])
  ])
  return roles
}

module.exports = getRolesForUserAndAccount
