<template>
  <v-navigation-drawer
    app
    fixed
    :stateless="$vuetify.breakpoint.lgAndUp"
    :value="$vuetify.breakpoint.lgAndUp || $store.state.navVisible"
    @input="$store.commit('setNavVisible', $event)"
    :width="collapsed ? 100 : ($vuetify.breakpoint.xs ? '100%' : '340')"
    class="left-navigation"
  >
    <div
      class="d-flex"
      style="height:100%;"
    >
      <v-navigation-drawer
        class="main-navigation"
        :width="100"
        stateless
        permanent
        :value="true"
        style="visibility: visible !important; transform: translateX(0) !important;"
        transition="slide-x-transition"
      >
        <div class="main-navigation-ordering">

          <router-link
            :to="{ path: '/' }"
            class="logo"
            @click="collapse = !collapse"
          >
            <img src="@/assets/icon.png" />
          </router-link>

          <router-link
            v-if="$hasRole('TECHNICAL') || $hasRole('SUPERUSER')"
            :to="{ path: '/tech' }"
          >
            <v-icon>fa-solid fa-file-code</v-icon>
            <div class="main-navigation-text">Tech</div>
          </router-link>

          <router-link
            v-if="$hasRole('CONTENT') || $hasRole('SUPERUSER')"
            :to="{ path: '/content' }"
          >
            <v-icon>fa-solid fa-book-open</v-icon>
            <div class="main-navigation-text">Content</div>
          </router-link>

        </div>
        <div
          slot="append"
          class="pb-4"
        >

          <a href="https://cdseo.com/blogs/documentation" target="_blank">
            <v-icon>fa-solid fa-question-circle</v-icon>
          </a>

          <router-link
            v-if="$hasRole('SUPERUSER')"
            :to="{ path: '/activity' }"
          >
            <v-icon>fa-solid fa-chart-simple</v-icon>
          </router-link>

          <router-link
            :to="{ name: 'Settings' }"
          >
            <v-icon>fa-solid fa-cog</v-icon>
          </router-link>

        </div>
      </v-navigation-drawer>

      <div
        v-if="!collapsed"
        class="secondary-navigation text-body-2 flex-grow-1 fill-height"
      >
            
        <div
          v-for="(secondaryNavRoute,index) in secondaryNavRoutes"
          :key="secondaryNavRoute.name"
        >
        
          <div class="left-nav-ordering">
            <div v-if="index === 0">
              <div class="d-flex justify-space-between align-center">
                <div class="secondary-navigation-title font-weight-bold">{{secondaryNavRoute.meta.nav}}</div>
                <v-icon
                  v-if="$vuetify.breakpoint.mdAndDown"
                  class="cursor"
                  @click="$store.commit('setNavVisible', false)"
                >
                  fa-close
                </v-icon>
              </div>
            </div>

            <!-- <div v-if="secondaryNavRoute.meta.divider" class="divider-text caption text-uppercase grey--text text--darken-1 font-weight-medium">
              {{ secondaryNavRoute.meta.divider }}
            </div>
            <v-divider v-if="secondaryNavRoute.meta.divider_hr" class="mt-4 mb-0" /> -->
            <router-link
              v-if="secondaryNavRoute.meta.name != 'Log Out'"
              :key="secondaryNavRoute.name"
              class="d-flex align-center pr-4"
              :to="{ name: secondaryNavRoute.name }"
            >
              <v-icon>{{ secondaryNavRoute.meta.icon }}</v-icon>
              {{ secondaryNavRoute.meta.name || secondaryNavRoute.name }}
            </router-link>

            <template v-else>

              <v-divider />

              <template>
                <a
                    class="d-flex align-center font-weight-medium"
                    @click="toggleTheme"
                  >
                    <v-icon>{{ themeIcon }}</v-icon>
                    <div>{{ themeText }}</div>
              </a>
              </template>

              <v-dialog
                v-model="dialog"
                width="400"
              >

                <template v-slot:activator="{ on }">
                  <a 
                    class="d-flex align-center font-weight-medium"
                    v-on="on"
                  >
                    <v-icon>{{ secondaryNavRoute.meta.icon }}</v-icon>
                    <div>Log Out</div>
                  </a>
                </template>

                <v-card
                >
                  
                  <v-card-text class="pa-10 text-body-1">
                    Are you sure you want to log out of your account?
                  </v-card-text>
                  <v-divider class="ma-0"></v-divider>
                  <v-card-actions class="pa-6 justify-end">
                    <v-btn large class="grey--text" plain @click="dialog = false">Cancel</v-btn>
                    <v-btn
                      large
                      color="primary"
                      class="confirm"
                      @click="logout"
                    >
                      Yes
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

            </template>

            <!-- BEN: Hard-coded for now -->
            <div
              v-if="['COLLECTION', 'PRODUCT', 'PAGE', 'ARTICLE'].includes(secondaryNavRoute.meta.type)"
              class="sub-navigation" 
            >
              <div
                class="d-flex align-center pr-4"
              >
                <router-link
                  :to="{ name: secondaryNavRoute.name }"
                >
                  All {{ secondaryNavRoute.meta.name }}
                </router-link>
                <div
                  class="count"
                >
                  <page-count-ref
                    :id="secondaryNavRoute.meta.type + '-all'"
                  />
                </div>
              </div>
              <div
                class="d-flex align-center pr-4"
              >
                <router-link
                  :to="{ name: secondaryNavRoute.name, query: { person_user_id: $store.state.userId } }"
                >
                  Assigned To You
                </router-link>
                <div
                  class="count"
                >
                  <page-count-ref
                    :id="secondaryNavRoute.meta.type + '-me'"
                  />
                </div>
              </div>
              <!-- <v-divider class="mt-2 mb-2"></v-divider> -->
              <div
                class="d-flex pr-3"
              >
                <router-link
                  :to="{ name: secondaryNavRoute.name, query: { filter: 'content_length' } }"
                >
                  Thin Content
                </router-link>
              </div>
              <div
                class="d-flex pr-3"
              >
                <router-link
                  :to="{ name: secondaryNavRoute.name, query: { filter: 'content_duplicates' } }"
                >
                  Duplicate Content
                </router-link>
              </div>
              <v-divider class="mr-4"></v-divider>
            </div>

          </div>
        </div>

      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { routes } from '@/router'
import PageCountRef from './References/PageCountRef'

export default {
  components: {
    PageCountRef
  },
  data () {
    return {
      dialog: false
    }
  },
  computed: {
    themeIcon () {
      return this.$vuetify.theme.dark ? 'fas fa-sun' : 'fas fa-moon'
    },
    themeText () {
      return this.$vuetify.theme.dark ? 'Light Mode' : 'Dark Mode'
    },
    collapse: {
      get () {
        return this.$store.state.navCollapse
      },
      set (value) {
        this.$store.commit('setNavCollapse', value)
      }
    },
    collapsed () {
      if (this.$vuetify.breakpoint.mdAndDown) {
        return false
      }
      if (
        this.$route.path == '/' ||
        this.$route?.meta?.navCollapse
      ) {
        return true
      }
      return this.$store.state.navCollapse
    },
    secondaryNavRoutes () {
      if (this.$route.meta?.nav) {
        const secondaryNavRoutes = routes
          .filter(route => {
            return this.$route.meta.nav === route?.meta?.nav &&
              !route?.redirect &&
              !route?.meta?.hidden &&
              (
                !route?.meta?.roles?.length ||
                route.meta.roles.reduce((acc, role) => acc || this.$hasRole(role), false)
              ) &&
              (
                !route?.meta?.modules?.length ||
                route.meta.modules.reduce((acc, module) => acc || this.$hasModule(module), false)
              )
          })
          .map(route => {
            // Use the default child route's name as the name for the parent route to link to
            if (!route.name && route.children?.length) {
              route = {
                ...route,
                name: route.children.find(childRoute => !childRoute.path)?.name
              }
            }
            return route
          })

        return secondaryNavRoutes
      }
      return []
    },
    accountItems () {
      const accounts = (this.$store.getters.accounts || [])
      // This happens when a superuser is accessing an account they aren't linked to
      if (this.$store.getters.account?.id && !accounts.find(a => a.id + '' === this.$store.getters.account?.id)) {
        accounts.push(this.$store.getters.account)
      }
      return accounts
        .map(account => ({
          text: account.name,
          value: account.id,
          subdomain: account.subdomain
        }))
    },
    isHomePage() {
      return this.$route.path === '/'
    }
  },
  methods: {
    async logout () {
      this.dialog = false
      try {
        document.body.style.opacity = 0.3
        await this.$store.dispatch('endSession')
      } finally {
        document.body.style.opacity = 1
      }
    },
    toggleTheme () {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      window.localStorage.setItem('darkMode', !!this.$vuetify.theme.dark)
    }
  }
}
</script>

<style lang="scss" scoped>
.v-dialog {
  .v-btn__content {
      font-weight: 600;
      font-size: 15px;
      letter-spacing: normal;
  }
}
.left-navigation {
  background-color: #1A5984 !important;
  color: #fff;
  .logo-container {
    width: 100px;
  }
  .v-text-field > .v-input__control > .v-input__slot:before {
    content: none;
  }
  .main-navigation {
    width: 100px;
    margin: 0 auto;
    text-align: center;
    background-color: #1A5984;
    align-items: center;
    .v-navigation-drawer__border {
      display: none;
    }
    a {
      display: block;
      padding: 16px;
      width: 100px;
      text-decoration: none;
      color: #fff;
      opacity: 0.65;
      i,button {
        font-size: 2.1em;
        color: #fff;
      }
      &.router-link-active {
        opacity: 1 !important;
        i {
          color: #fff !important;
        }
      }
    }
    a:hover {
      opacity: 1;
    }
    .logo {
      background-color: unset;
      opacity: 1;
    }
    .main-navigation-text {
      margin-top: 0.6em;
      margin-bottom: 1em;
      font-size: 0.86em;
    }
  }
  .secondary-navigation {
    background-color: #fff;
    color: #000;
    padding-right: 16px;
    padding-left: 16px;
    width: 240px;
    a {
      display: block;
      padding: 14px 0px;
      margin-top: 4px;
      color: #000;
      text-decoration: none;
      font-size: 1em;
      i {
        font-size: 18px;
        color: #555;
        width: 52px;
        text-align: center;
      }
      > div {
        text-decoration: none;
        padding-left: 4px;
      }
      &:not(.sub-navigation a).router-link-active {
        background-color: #f3f6f9;
        border-radius: 8px;
        font-weight: bold !important;
        i, div {
          font-weight: bold;
          color: #000;
        }
      }
      > .v-icon {
        font-size: 18px;
      }
      &:not(.sub-navigation a):hover {
        background-color: #f3f6f9;
        border-radius: 8px;
      }
    }
    .divider-text {
      padding: 17px 12px 0px 8px;
      color: #000;
      text-decoration: none;
    }
    .secondary-navigation-title {
      color: #1A5984;
      font-weight: bold;
      text-transform: uppercase;
      padding: 24px 12px 17px 8px;
      border-bottom: 1px solid #e1e1e1;
      width: 100%;
    }
  }

  .sub-navigation {
    margin-top: .6em;
    color: #000;
    padding-left: 16px;
    padding-right: 16px;
    width: 240px;
    a {
      display: block;
      padding: 4px 0px;
      color: #000;
      text-decoration: none;
      font-size: 1em;
      flex-grow: 100;

      letter-spacing:0px;
      &.router-link-active {
        font-weight: normal !important;
      }
      &.router-link-exact-active {
        font-weight: bold !important;
      }
    }
    div.count > span {
      background-color:#f3f6f9;
      padding: 0.2em;
      min-width: 36px;
      border-radius: 4px;
      font-weight: 500;
      color: #000;
      text-align: center;
      display: inline-block;
    }
  }
}
.theme--dark .left-navigation {
  .main-navigation {
    background-color: #0a3855;
  }
  .secondary-navigation {
    background-color: #1e1e1e;
    color: #fff;
    a {
      color: #fff;
      &:not(.sub-navigation a).router-link-active {
        background-color: #292929;
        i, div {
          color: #fff;
        }
      }
      &:not(.sub-navigation a):hover {
        background-color: #292929;
      }
    }
    .divider-text {
      color: #292929;
    }
    .secondary-navigation-title {
      color: #1A5984;
      border: none;
    }
  }

  .sub-navigation {
    margin-top: .6em;
    color: #fff;
    padding-left: 16px;
    padding-right: 16px;
    width: 240px;
    a {
      display: block;
      padding: 4px 0px;
      color: #ccc;
      text-decoration: none;
      font-size: 1em;
      flex-grow: 100;

      letter-spacing:0px;
      &.router-link-active {
        font-weight: normal !important;
        color: #fff;
      }
      &.router-link-exact-active {
        font-weight: bold !important;
      }
    }
    div.count > span {
      background-color:#292929;
      padding: 0.2em;
      min-width: 36px;
      border-radius: 4px;
      font-weight: 500;
      color: #ccc;
      text-align: center;
      display: inline-block;
    }
  }
}
a:not(.router-link-active) + .sub-navigation {
    display: none;
}
</style>