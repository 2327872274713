import getPrivilegesForRoles from 'shared/permissions/getPrivilegesForRoles'
import { intersection } from 'lodash'

export default {
  computed: {
    $modules () {
      return this.$store?.getters?.modules || []
    },
    $roles () {
      return this.$store?.getters?.roles || []
    },
    $privileges () {
      return getPrivilegesForRoles(this.$store?.getters?.roles)
    }
  },
  methods: {
    // Supports array of modules where all need to exist or a single module that needs to exist
    $hasModule (module) {
      if (Array.isArray(module)) {
        return intersection(this.$modules, module).length === module.length
      }
      return this.$modules.includes(module)
    },
    $hasRole (role) {
      if (this.$roles.includes(role)) {
        return true
      }
      return false
    },
    $hasPrivilege (privilege) {
      return this.$privileges.includes(privilege)
    }
  }
}
