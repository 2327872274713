import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

const colors = {
  primary: '#0269A5',
  error: '#db4c62',
  success: '#20a27c',
  ignore: '#6e8ca2',
  icon: '#555555'
}

export default new Vuetify({
  icons: {
    iconfont: 'fa'
  },
  theme: {
    dark: ![false, 'false', null, undefined, ''].includes(window.localStorage.getItem('darkMode')) && ['true', true].includes(window.localStorage.getItem('darkMode')),
    themes: {
      light: {
        ...colors
      },
      dark: {
        ...colors
      }
    }
  }
});
