import Model from './Model'

const getUserFriendlyName = (user) => {
  if (!user) {
    return ''
  }

  return user?.name || user?.email || user?.phone || ('User #' + user?.id)
}

export default class User extends Model {
  static entity = 'user'

  static fields () {
    return {
      id: this.attr(null),
      email: this.attr(''),
      phone: this.attr(''),
      name: this.attr(''),
      roles: this.attr(''),
      picture: this.attr(''),
      timezone: this.attr(''),
      created_at: this.attr(''),
      last_seen_at: this.attr('')
    }
  }

  get friendlyName () {
    return getUserFriendlyName(this)
  }
}