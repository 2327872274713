import Model from './Model'

export default class AccountUser extends Model {
  static entity = 'account_user'

  static fields () {
    return {
      id: this.attr(undefined),
      user_id: this.attr(undefined),
      account_id: this.attr(undefined),
      name: this.attr(undefined),
      roles: this.attr(undefined),
      settings: this.attr(undefined),
      created_at: this.attr(undefined),
      last_seen_at: this.attr(undefined)
    }
  }
}
