<template>
  <v-app>
    <template
      v-if="
        $store.getters.account &&
        $store.getters.billing.plan &&
        (
          $store.getters.billing.plan === 'ENTERPRISE' ||
          !!$store.getters.billing.period_end_at
        ) &&
        (
          !$store.getters.showAuth ||
          $store.getters.user
        ) &&
        !$store.getters.planUsersExceeding
      "
    >
      <left-navigation />

      <!-- Mobile Header -->
      <div
        v-if="$vuetify.breakpoint.mdAndDown"
      >
        <div
          class="mobile-menu"
        >
          <div 
            class="d-flex justify-space-between align-center"
            color="primary"
          >
            <div
              class="py-5 px-8" width="120"
              @click="$store.commit('setNavVisible', true)"
              style="cursor: pointer;"
            >
              <v-icon
                fab
                color="white"                
                v-if="!isHomePage"
              >
                fa-solid fa-bars
              </v-icon>
            </div>
            <div class="pa-5 center">
              <router-link to="/" class="d-inline-flex">
                <img src="@/assets/logos/cdseo-logo-white.png" class="logo" width="180" height="45" alt="Dashboard" />
              </router-link>
            </div>
            <div class="py-5 px-8">
              <alert-menu 
                v-if="$vuetify.breakpoint.mdAndDown"
              />
            </div>

          </div>
        </div>
      </div>

      <v-main
        :class="{ 'full-width': $route.meta?.noPadding }"
        :style="$vuetify.breakpoint.mdAndDown ? { height: 'calc(100% - 92px)' } : {}"
      >
        <div
          :class="$route.meta?.noPadding ? [] : [
            $vuetify.breakpoint.xs ? 'pt-6 pa-2' : '',
            $vuetify.breakpoint.sm ? 'pa-4' : '',
            $vuetify.breakpoint.md ? 'pa-6' : '',
            $vuetify.breakpoint.lgAndUp ? 'pa-10' : ''
          ]"
          style="height: 100%"
        >
          <router-view />
        </div>
      </v-main>

    </template>

    <template
      v-else-if="$store.getters.account && $store.getters.planUsersExceeding"
    >
      <div class="pa-10 text-center">
        <img :src="require($vuetify.theme.dark ? '@/assets/logos/cdseo-logo-white.png' : '@/assets/logos/cdseo-logo-blue.png')" />
      </div>
      <force-remove-account-users-dialog />
    </template>

    <v-alert
      style="position: fixed; top: 0; left: 0; width: 100%; z-index: 100000000;"
      v-if="$store.state.appError"
      icon="fa fa-exclamation-circle"
      dark
      color="error"
      dismissible
      @input="$store.commit('setAppError', null)"
    >
      <span>{{ $store.state.appError }}</span>
    </v-alert>

    <auth-view
      v-if="$store.getters.showAuth"
    />

    <accounts-view
      v-else-if="!$store.getters.account"
    />

    <div
      v-if="$store.state.loading"
      style="width: 100%; height: 100%; position: fixed; left: 0; top: 0; display: flex; align-items: center; justify-content: center; z-index: 100000000; background-color: rgba(0, 0, 0, 0.4);"
    >
      <!-- <v-progress-circular
        indeterminate
        color="white"
      /> -->
      <div
        style="width: 280px; padding: 60px;  text-align:center;"
        :style="$vuetify.theme.dark ? 'background-color: #292929' : 'background-color: #fff;'"
        class="rounded"
      >
        <img src="https://s3.amazonaws.com/cdn.cdseo.com/assets/cdseo-loading.gif?v=7" style="width: 100%; max-width: 150px;">
      </div>
    </div>
    <shopify-app-embed-instructions
      v-if="showShopifyAppEmbedInstructions"
    />
  </v-app>
</template>

<style lang="scss">
html, body {
  min-height: 100%;
  background-color: #f3f6f9;
}
.v-application, .v-application--wrap, .v-main, .v-main__wrap {
  height: 100%;
  background-color: #f3f6f9;
}
.theme--dark .v-application--wrap, 
.theme--dark .v-main, 
.theme--dark .v-main__wrap {
  background-color: #292929;
}
.v-main.full-width {
  .v-main__wrap {
    max-width: 100% !important;
  }
}
.mobile-menu {
  background-color: #1a5984;
  color: #fff;
}
.theme--dark .mobile-menu {
  background-color: #0a3855;
}
.mobile-menu .logo {
  width: 100%;
  max-width: 200px;
  height: auto;
}
</style>

<script>
import AlertMenu from '@/components/AlertMenu/AlertMenu.vue'
import LeftNavigation from '@/components/LeftNavigation.vue'
import AuthView from '@/components/Auth/AuthView.vue'
import AccountsView from '@/components/Auth/AccountsView.vue'
import { Config } from '@/models'
import ShopifyAppEmbedInstructions from '@/components/ShopifyAppEmbedInstructions.vue'
import ForceRemoveAccountUsersDialog from './components/ForceRemoveAccountUsersDialog.vue'

export default {
  name: 'App',
  components: {
    AlertMenu,
    LeftNavigation,
    AuthView,
    AccountsView,
    ShopifyAppEmbedInstructions,
    ForceRemoveAccountUsersDialog
  },
  computed: {
    appEmbedConfig () {
      return Config.query()
        .where(record => record.key === 'shopify_app_embed_ping')
        .first()
    },
    showShopifyAppEmbedInstructions () {
      return this.$store.getters.billing?.plan && this.$store.getters.shopifyIntegration?.public?.type === 'OAUTH' && !this.appEmbedConfig
    },
    isHomePage () {
      return this.$route.path === '/'
    },
    systemLoading () {
      return $store.getters?.systemLoading
    }
  },
  watch: {
    systemLoading (value, oldValue) {
      if (!value && !!oldValue && !!oldValue.initial) {
        // When systemLoading disappears when previously was there, reload the page to get all new data.
        window.location.reload(true)
      }
    }
  },
  created () {
    // If there's an invitation token provided, check the timestamp on it and verify that it's still valid
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    if (urlParams.get('invitation_token')) {
      try {
        const parsedToken = JSON.parse(atob(urlParams.get('invitation_token')))
        if ((parsedToken?.t || 0) < (Date.now() / 1000)) {
          this.$store.commit('setAppError', 'This invitation has expired.')
        }
      } catch (e) {
        console.warn(e)
        this.$store.commit('setAppError', 'This invitation is invalid.')
      }
    }
  }
}
</script>