import Model from './Model'

export default class NotFoundError extends Model {
  static entity = 'not_found_error'

  static fields () {
    return {
      id: this.attr(null),
      account_id: this.attr(''),
      url: this.attr(''),
      data: this.attr(''),
      status: this.attr(''),
      page_id: this.attr(''),
      by_user_id: this.attr(null),
      hit_count: this.attr(''),
      last_hit_at: this.attr(''),
      created_at: this.attr('')
    }
  }
}